.decor {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0; 
    
}
.decor-svg { 
    svg {
        position: inherit;
        left:inherit;
        right: inherit;
        width: inherit;
    } 
    // svg color
    &[data-color='green'] {
        svg {
            fill: var(--color-green);
        }
    }
    &[data-color='light-green'] {
        svg {
            fill: var(--color-light-green);
        }
    }
    &[data-color='light-brown'] {
        svg {
            fill: var(--color-light-brown);
        }
    }
    &[data-color='light-violet'] {
        svg {
            fill: var(--color-light-violet);
        }
    }
    &[data-color='light'] {
        svg {
            fill: var(--color-light);
        }
    }
    &[data-color='white'] {
        svg {
            fill: var(--color-white);
        }
    }
    // wrapper bg color
    &[data-bg='white'] {
        background-color: var(--color-white); 
    }
    &[data-bg='light'] {
        background-color: var(--color-light); 
    }
    &[data-bg='green'] {
       background-color: var(--color-green); 
    }
    &[data-bg='light-green'] {
        background-color: var(--color-light-green); 
     }
    &[data-bg='light-brown'] {
        background-color: var(--color-light-brown); 
    }
}


// section wrapper data variant overrides
[data-variant='decor-top'] {
    .decor {
        height: 50%;
        max-height: 420px;
    }
}
[data-variant='decor-bottom'] {
    .decor {
        height: 30%;
        max-height: 320px;
    }
}