.gallery {
    @include base-grid();
    // override
    grid-template-columns: repeat(6,1fr);
    grid-gap: 20px 20px;
    gap: 20px 20px;
    grid-auto-flow: dense;
}

.gallery-item {
    position: relative;
    --cols: 2;
    
    
    grid-column-start: span var(--cols);
    grid-row-start: span var(--rows);

    overflow: hidden;
    background: #857b5c;
    line-height: 0;
    /*
    grid-column: 1/-1;
    grid-row: 1; 
    @include media-query('md') {
        grid-column: 6/12;
        grid-row: 1; 
        // grid-column: 1/5; 
        // grid-row: 1; 
    }
    @include media-query('xl') {
        grid-column: 6/12;
       
    }
    */
}

.gallery--home {

    .gallery-item:first-child {
    --cols: 4;
    --rows: 2;
  }
  /*
  .gallery-item:nth-child(5) {
    --cols: 4;
    --rows: 2;
  }
  .gallery-item:nth-child(6) {
    --rows: 4;
    --rows: 2;
  }
  .gallery-item:nth-child(7) {
    --cols: 4;
    --rows: 2;
  }
  */
}



/// GSLIDE OVERLAY
.gslide-image {
    img {
        border-radius: 10px;
    }
}
.glightbox-mobile .goverlay, .goverlay {
    background: #857b5c;
}
