$boxColor: var(--color-black);
$boxColorAlt: var(--color-brown);
$boxTransition: all 0.2s ease-in-out;
$boxIconHover: var(--color-green);

.boxes {
    display: flex; 
    margin-bottom: 0;
    padding-left: 0;

    li {
        list-style-type: none;
        padding-left: 0;
    }
}

.box {
    color: $boxColor;
    > a {
        text-decoration: none;
        color: inherit;
        transition: $boxTransition; 

        &:hover {
            // color: #232323; 
            svg {
                // fill:#232323; 
                fill:$boxIconHover;
                transform: translateY(-0.15rem);
            }
            &.box-inner .shape {
                width: 100%; 
                opacity: 1;
            }
        }

    }
}

.box-inner {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .shape {
        position: absolute;
        width: 0;
        max-width: 100%;
        bottom: -0.75rem; 
        left: 0;
        will-change: width, opacity;
        transition: width 0.3s ease-in-out, opacity 0.3s ease-in-out;
    }
}

// icon
.box-icon {
    position: relative;
    padding: 0 0.75rem;
    transition: $boxTransition; 
    
    span {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    svg {
        fill:$boxColor;
        transition: $boxTransition; 
    }
}
.box-icon svg {
     // height: 50px;
     // width: 50px;  
     width: clamp(1rem, 1.1466rem + 1.5707vw, 2.5rem);  
}

// content
.box-content {
    font-family: var(--font-family-sansCond); 
    font-weight: 700;
    line-height: 1;
}
.box-content-label { 
    font-size: 0.825rem; 
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    margin-bottom: 0.25rem; 
}
.box-content-value {
    line-height: 1.35;
    transition: $boxTransition; 
    // font-size: clamp(0.5rem, 0.65rem + 0.5707vw, 1.5rem); 
}

/*
* base modifiers
*/
// .boxes-icons {}

/*
* context-sensitive variants
*/
.boxes {
    &[data-variant*='header-contact'] {
        
        display: none; 
        @include media-query('sm') {
            display: flex; 
        }

        .box {
            &:not(:last-child) {
                @include media-query('lg') {
                    margin-right: clamp(0.5rem, 0.825rem + 1.1vw, 2rem); 
                }
            }
        }
        .box-inner {
            .shape {
                @include media-query('xlmax') {
                    display: none; 
                }
            }
        }
        .box-content {
            display: none; 
            @include media-query('lg') {
                display: block;
            }
        }

        .box-content-label { 
            margin-bottom: 0rem; 
            @include media-query('xl') {
                margin-bottom: 0.25rem; 
            }
        }

        .box-content-value {
            font-size: clamp(0.875rem, 0.7326rem + 0.6329vw, 1rem);
            display: none; 
            @include media-query('xl') {
                display: flex; 
            }
        }

    }
    &[data-variant*='nav-mobile-contact'],
    &[data-variant*='rows']  {
        display: block; 

        .box {

            &:not(:last-child) {
                margin-bottom: 2rem; 
                @include media-query('xl') {
                    margin-bottom: 3.25rem; 
                }
            }
        }

        .box-icon {
            padding: 0.75rem 0.75rem 0.75rem 0;
        }

        .shape {
            left: 0 !important; 
        }
    }
}