/* vars */
// $frameWidth: 16px;
// $headerHeight: 80px;

html {
    text-size-adjust: 100%; // experimental
    scroll-behavior: smooth;
}

/* Body */
body {
    font-family: var(--font-family-sans); 

    // font-size: 16px;
    font-size: clamp(0.875rem, 0.7326rem + 0.6329vw, 1.125rem); // 360 - 992 px => 0,875 = 14px | 1,125 rem = 18px
    line-height: 1.5;
    font-weight: 400;
    letter-spacing: .3px;

    @include media-query('lg') {
        // font-size: 18px;
        line-height: 1.44;
        letter-spacing: .2px;
    }
    
    // color: var(--color-text);
    // background: var(--color-light);
    // overflow-x: hidden;
}


