/// BASE SIZE
/// All calculations are based on this. It’s recommended that
/// you keep it at 1rem because that is the root font size. You
/// can set it to whatever you like and whatever unit you like.
///
$gorko-base-size: 1rem;

/// SIZE SCALE
/// This is a Major Third scale that powers all the utilities that
/// it is relevant for (font-size, margin, padding). All items are
/// calcuated off the base size, so change that and cascade across
/// your whole project.
///
$gorko-size-scale: (
  '300': $gorko-base-size * 0.8,
  '400': $gorko-base-size,
  '500': $gorko-base-size * 1.25,
  '600': $gorko-base-size * 1.6,
  '700': $gorko-base-size * 2,
  '900': $gorko-base-size * 3
);

/// COLORS
/// Colors are shared between backgrounds and text by default.
/// You can also use them to power borders, fills or shadows, for example.
///
/// gray 208 209 201
/// green 19 167 61
/// violet 161 31 127
/// brwon 133 123 92
$gorko-colors: (
  'dark': #111111,
  'light': #f3f3f3,
  'black': #000000,
  'white': #ffffff,
  'gray': #D0D1C9,
  'green': #13A73D,
  'light-green': #e5f5ea,
  'violet': #A11F7F,
  'light-violet': #f2eaf0, // #f5e6f1
  'brown': #857B5C,
  'light-brown': #f2f1ed
);

/// CORE CONFIG
/// This powers everything from utility class generation to breakpoints
/// to enabling/disabling pre-built components/utilities.
///
$gorko-config: (
  'css-vars': (
    'color': $gorko-colors,
    'font-family': (
        'hand': $hand,
        'handAlt': $handAlt,
        'sans': $sans,
        'sansBold': $sansBold,
        'sansBlack': $sansBlack,
        'sansLight': $sansLight,
        'headline': $sansCondBold
    ),
    'grid': (
      'columns': 12,
       'gutter': $gorko-base-size
    )
  ),
  'bg': (
    'items': $gorko-colors,
    'output': 'standard',
    'property': 'background'
  ),
  'color': (
    'items': $gorko-colors,
    'output': 'standard',
    'property': 'color'
  ),
  'box': (
    'items': (
      'block': 'block',
      'flex': 'flex',
      'hide': 'none',
      'show': 'inherit'
    ),
    'output': 'responsive',
    'property': 'display'
  ),
  'font': (
    'items': (
      'base': 'Helvetica, Arial, sans-serif'
    ),
    'output': 'standard',
    'property': 'font-family'
  ),
  'gap-top': (
    'items': $gorko-size-scale,
    'output': 'standard',
    'property': 'margin-top'
  ),
  'gap-right': (
    'items': $gorko-size-scale,
    'output': 'standard',
    'property': 'margin-right'
  ),
  'gap-bottom': (
    'items': $gorko-size-scale,
    'output': 'standard',
    'property': 'margin-bottom'
  ),
  'gap-left': (
    'items': $gorko-size-scale,
    'output': 'standard',
    'property': 'margin-left'
  ),
  'pad-top': (
    'items': $gorko-size-scale,
    'output': 'standard',
    'property': 'padding-top'
  ),
  'pad-right': (
    'items': $gorko-size-scale,
    'output': 'standard',
    'property': 'padding-right'
  ),
  'pad-bottom': (
    'items': $gorko-size-scale,
    'output': 'standard',
    'property': 'padding-bottom'
  ),
  'pad-left': (
    'items': $gorko-size-scale,
    'output': 'standard',
    'property': 'padding-left'
  ),
  'stack': (
    'items': (
      '300': 0,
      '400': 10,
      '500': 20,
      '600': 30,
      '700': 40
    ),
    'output': 'standard',
    'property': 'z-index'
  ),
  'text': (
    'items': $gorko-size-scale,
    'output': 'responsive',
    'property': 'font-size'
  ),
  'weight': (
    'items': (
      'light': '300',
      'regular': '400',
      'bold': '700'
    ),
    'output': 'standard',
    'property': 'font-weight'
  ),
  'width': (
    'items': (
      'full': '100%',
      'half': calc(100%/2),
      'quarter': calc(100%/4),
      'third': calc(100%/3)
    ),
    'output': 'responsive',
    'property': 'width'
  ),
  'breakpoints': (
    'smax': '(max-width: 35.999em)',
    'sm': '(min-width: 36em)',
    'mdmax': '(max-width: 47.999em)',
    'md': '(min-width: 48em)',
    'lgmax': '(max-width: 61.999em)',
    'lg': '(min-width: 62em)',
    'xlmax': '(max-width: 75.999em)',
    'xl': '(min-width: 76em)',
    'xxlmax': '(max-width: 92.499em)',
    'xxl': '(min-width: 92.5em)'
  )
);