.section {
    position: relative;
    // padding: 45px 20px; // padding 70px
    padding: 70px 25px;
    min-height:500px;
    overflow: hidden; 
    
    @include media-query('sm') {
        padding: 70px 40px;
    }

    @include media-query('lg') {
        padding: 140px 70px;
    }

    // offset section border
    &[data-variant='offset-border-bottom'] {
        &:after {
            display: block;
            content: '';
            position: absolute;
            height: 160px;
            background-color: var(--color-white);
            width: 100%;
            bottom: 0;
            left: 0;
            right: 0; 
            z-index: 0;
        }
    }

    // 
    &.section-teaser {
        min-height: initial;
        padding-top: 70px; 
    }
}

.s-content,
.hero-content {
    @include wrapper(); 
    @include base-grid(); 
}

.s-header {
    position: relative;
    margin-bottom: 2rem;
}

.s-content-text {
    position: relative;
    
    .text {
        position: inherit;
        z-index: 2; // above rellex and images 
    }
}

// section content by types
.s-content {
    // TEXT - IMAGE
    &[data-variant*='txt-img'],
    &[data-variant*='txt-img-dt-rev'] {
        @include media-query('lg') {
            min-height: 600px;
        }
        // columns
        .s-content-image {
            position: relative;
        } 
        .s-content-text {
            align-self: center;
        } 
        // image fine control
        .image-ar {
            max-height: 480px;
            /* @include media-query('md') {
                // max-height: 420px;
            } */
            @include media-query('xl') {
                max-height: 100%; 
            }
        }
    }
    &[data-variant*='txt-img'] {
        .s-content-image {
            grid-column: 1/-1;
            grid-row: 1; 
            @include media-query('md') {
                grid-column: 6/12;
                grid-row: 1; 
                // grid-column: 1/5; 
                // grid-row: 1; 
            }
            @include media-query('xl') {
                grid-column: 6/12;
               
            }
        }
        .s-content-text {
            grid-column: 1/-1;
            grid-row: 2; 
            @include media-query('md') {
                grid-column: 1/5; 
                grid-row: 1; 
            }
            @include media-query('xl') {
                grid-column: 2/5; 
                // grid-row: 1; 
            }
        }
    }
    &[data-variant*='txt-media'] {
        .s-content-gallery {
            grid-column: 1/-1;
            grid-row: 2; 
            /*
            @include media-query('md') {
                grid-column: 6/12;
                grid-row: 1; 
                // grid-column: 1/5; 
                // grid-row: 1; 
            }
            */
            @include media-query('xl') {
                // grid-column: 6/12;
                grid-column: 2/8;
                grid-row: 1; 
               
            }
        }
        .s-content-text {
            grid-column: 1/-1;
            grid-row: 1; 
            /*
            @include media-query('md') {
                grid-column: 1/5; 
                grid-row: 1; 
            }
            */
            @include media-query('xl') {
                // grid-column: 2/5; 
                grid-column: 9/12;
                grid-row: 1; 
            }
        }
    }
    &[data-variant*='txt-img-dt-rev'] {
        .s-content-image {
            grid-row: 2;
            @include media-query('md') {
                grid-row: 1;
            }
        }
        .s-content-text {
            grid-row: 1;
            @include media-query('md') {
                grid-row: 1;
            }
        }
    }

    // TEXT - CTN
    
    &[data-variant*='txt-ctn'] {
        color: var(--color-light); 
        border-radius: 20px; 
        padding-top: 50px;
        padding-bottom: 50px; 

        @include media-query('md') {
            grid-gap: 2.5rem; 
            padding-top: 75px;
            padding-bottom: 75px; 
        }

        .h-hand {
            color: var(--color-light); 
        }
        .s-content-text {
            grid-row: 1;
            grid-column: 2/12; 
            @include media-query('md') {
                grid-row: 1;
                grid-column: 2/8; 
            }

            p:last-child {
                
                @include media-query('md') {
                    margin-bottom: 0;
                    
                }
            }
        }
        .s-content-ctn {
            grid-row: 2;
            grid-column: 2/12; 

            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            @include media-query('md') {
                grid-row: 1;
                grid-column: 8/12; 
            }
        }

        .text-ctn {
            .btn {
                @include media-query('md') {
                    margin-top: 2rem;
                }
            }
            
        }
    }


    // HEADER - FULL WIDTH OBJECT below (eg Slider, Cards etc)
    &[data-variant*='header-cards'],
    &[data-variant*='header-grid'] {
        // header
        .s-header {
            margin-left: auto;
            margin-right: auto;
            max-width: 455px;
            grid-column: 1/-1;

            &.--centered {
                text-align: center; 
            }
        }
    }
    &[data-variant*='header-cards'] {
        
        // Objects
        // Cards
        .s-cards {
            // overflow: hidden;
            position: relative;
            grid-column: 1/-1;
            
            @include media-query('lg') {
                grid-column: 2/12;
            }

            .cards {
                display: flex;
                flex-direction: column;
                // width: 100%;  mdmax
                @include media-query('md') {
                    flex-direction: row;
                }
            }
        }
    }
    &[data-variant*='header-grid'] {
        // grid contact
        .grid-contact {
            grid-column: 1/-1;
            display: grid;
            grid-template-columns: repeat(12,1fr);
            grid-gap: 0;
            gap: 0;
            height: 100%;
            @include media-query('md') {
                background-color: var(--color-white); 
                border-radius: 20px;
                box-shadow: 5px 5px 10px rgb(0 0 0 / 35%);
            }
        }

        .grid-contact-data {
            position: relative;
            grid-column: 1/-1;
            @include media-query('md') {
                grid-column: 2/5;
                align-self: center;
            }

            .shape {
                @include media-query('lgmax') {
                    display: none;
                }
            }

            .module {
               margin-bottom: 3rem;
                @include media-query('md') {
                    margin-top: 2rem;
                    padding: 1rem 0; 
                }
            }

            // hover color override
            .box > a:hover {
                color: var(--color-violet);
                svg {
                    fill: var(--color-violet); 
                }
                .shape-dot.black {
                    background-image: radial-gradient(var(--color-violet) 2px,transparent 2.5px);
                }
            }
        }
        .grid-contact-hrs {
            grid-column: 1/-1;
            background-color: var(--color-white); 
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;

            @include media-query('md') {
                grid-row:2; 
                grid-column: 1/13;
                border-top-left-radius: 0px;
                border-top-right-radius: 0px;
                border-bottom-left-radius: 20px;
                border-bottom-right-radius: 20px;
            }

            .grid-contact-hrs-inner {
                color: var(--color-white);
                // background-color: var(--color-violet);
                background-color: var(--color-green);
                padding: 20px 20px 20px 20px; 
                text-align: center;
                border-top-left-radius: 20px;
                border-top-right-radius: 20px;

                @include media-query('md') {
                    padding: 20px 20px 20px 20px; 
                    border-top-left-radius: 0px;
                    border-top-right-radius: 0px;
                    border-bottom-left-radius: 20px;
                    border-bottom-right-radius: 20px;
                }
            }
        }
        .grid-contact-map {
            grid-column: 1/-1;
            background-color: var(--color-white); 
            padding: 40px; 
            // height: 100%; 
            border-bottom-left-radius: 20px;
            border-bottom-right-radius: 20px;

            @include media-query('smax') {
                padding: 20px; 
            }
            @include media-query('mdmax') {
                box-shadow: 5px 5px 10px rgb(0 0 0 / 35%);
            }
            
            @include media-query('md') {
                grid-row:1; 
                grid-column: 7/13;

                border-bottom-left-radius: 0px;
                border-bottom-right-radius: 0px;
                border-top-right-radius: 20px;
            }
        }

        // map height
        .map {
            // --map-height: 56.25%;
            --map-height: 100%;
            @include media-query('md') {
                --map-height: 75%;
            }
        }
    }
}

// hero
.hero {
    height: 100vh;
    min-height: auto!important; 
    max-height: 60vh;
    padding: 0;

    @include media-query('mdmax') {
        max-height: calc(100vw + 5vh);
    }
    @include media-query('md') {
        max-height: calc(60vw);
    }
    @include media-query('lg') { 
        max-height: 900px;
    }
}
.hero-media {
    position: absolute;   
    width: 100%;
    height: 100%;  
    z-index: 1; 
}
.hero-media-inner {
    position: relative;
    margin: 0 auto;
    width: inherit;
    height: inherit; 
    max-width: 1920px;
    border-bottom-left-radius: 40px;
    border-bottom-right-radius: 40px;
    overflow: hidden;
}
.hero-content {
    position: relative;
    z-index: 2;
}
.hero-lead {
    grid-column: 1/-1;
    align-self: center;
    
    @include media-query('md') {
        grid-column: 2/9;
    }
    @include media-query('lg') {
        // grid-column: 3/8;
        // grid-column: 7/11;
    }

    // styling individual formats
    .h-hand {
        color: var(--color-black); 
    }
    .hero-title {
        font-size: calc(1.5rem + 1.15vw);
        line-height: 1.15;
        max-width: 25ch;
        margin-bottom: 2.5rem;
        .typer, .cursor {
            color: var(--color-green)!important; 
        }

        @include media-query('mdmax') {
            margin-bottom: 1rem;
        }
    }
    .circle-inner {
        // more space above
        @include media-query('lg') {
            margin-top: 8rem;
        }
    }
}
// Hero Variants
/*
.hero {
    @at-root #{&}--home {
        background-color: var(--color-light-green);
    }
}
 */
// hero specific formats ?!
.circle {
    position: relative;
    width:50vw;
    height:50vw;
    max-width: 820px;
    max-height: 820px; 
    border-radius:25vw;
    padding: 5vw;
    // font-size:50px;
    // color:#fff;
    // line-height:500px;
    text-align:center;
    // background: rgba(19,167,61,0.35);
    background: rgba(255,255,255,0.51);

    display:flex;
    // justify-content: flex-start;
    justify-content: center;
    align-items: center;

    @include media-query('mdmax') {
        margin: 0 auto;
        width: 92vw;
        height: 92vw;
        border-radius: 46vw;
    }
}


// OVERRIDES NAMED SECTIONS

section#praxisuebergabe {
    .s-content[data-variant*='txt-img'] { 
        .image-ar {
            max-height: 480px;
            /* @include media-query('md') {
                // max-height: 420px;
            } */
            @include media-query('xl') {
                max-height: 480px;
            }
        } 
    }
}
section#kontakt {
    padding-bottom: 1rem;
}
