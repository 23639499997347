// menus (on list elements)
.menu {
    font-family: var(--font-family-sansCond); 
    font-weight: 700;
    letter-spacing: 1px;
}
.menu li {
    list-style-type: none;
}

// Nav Mobile
.nav-mobile {
    position: fixed;
    z-index: z('mobileNav');

    top: 0;
    left: 0; 
    transform: translateX(-100vw); // change me (js)
    opacity: 0; // change me (js) 
    
    z-index: 0;
    min-width: 100vw;
    height: 100%;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    background: var(--color-white);

    transition: opacity 0.3s ease-in-out, transform 0.23s ease-in-out; 
    will-change: opacity,transform;

    &.is-active {
        // left: -100vw;
        transform: translateX(0vw);
        opacity: 1;
    }

     // overrides 
     .boxes {
        .box {
            .shape {
                display: none!important;
            }
        }
        .box-icon {
            padding-left: 0;
        }
        .box-content-value {
            white-space: break-spaces;
        }
    }

    > .shape,
    .nav-mobile-footer > .shape {
        display: block;
        position: absolute;
    }
}
.nav-mobile-inner {
    padding-top: 80px;
    padding-right: 40px;
    padding-left: 40px;

    .menu-primary {
        margin-bottom: 2rem;
    }
    .nav-mobile-contact {
        margin-bottom: 5rem;
    }
}

.nav-mobile-contact {
    position: relative;
    margin-bottom: 0.75rem;
}
.nav-mobile-footer {
    position: relative;
    margin-bottom: 0.75rem;
}

// Nav Desktop
// .nav-desktop {}

.menu {
    padding-left: 0; 
    li {
        padding-left: 0;  
    }
        
    // menu primary
    &.menu-primary {

        text-transform: uppercase;

        &[data-variant='menu-desktop'] {
            display: flex;
            justify-content: center;
            align-items: center;

            > li a:not(.logo-icon) {
                // display: block;
                // padding: 16px;
                display: block;
                position: relative;
                font-weight: 300;
                line-height: 22px;
                padding: 11px 14px;
                color: var(--color-white);
                font-size: 0.875rem;
                text-decoration: none;
                transition: all .2s ease-in-out;

                &:hover {
                    background-color: rgba(0,0,0,0.15);
                }

                @include media-query('lg') {
                    margin: 10px 4px;
                    padding-top: 3px;
                    padding-bottom: 3px;
                    border-radius: 14px;
                }
            }

            // logo icon (home / scroll top)
            > li a.logo-icon {
                display: block;
                position: relative;
                // padding: 3px;
                height: 32px;
                width: 32px; 
                // padding-left: 7px;
                margin-right: 7px;
                svg {
                    height: 100%; 
                    width: 100%; 
                    fill: var(--color-white); 
                }
            }
        }
        
        &[data-variant='menu-mobile'] {
            // font-size: 24px;
            font-size: clamp(1rem, 1.35rem + 0.6329vw, 1.825rem);
        }
    }
    
    // menu secondary
    &.menu-secondary {
        &[data-variant='menu-legal-footer'] {
            letter-spacing: 0px; 
        }
    }
}

// body
.navigation-active {
    overflow-y: hidden; 
}