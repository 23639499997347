.cards {
    display: flex;
    // flex-wrap: wrap; 
    // justify-content: space-between;
    flex-wrap: wrap;
    align-items: stretch; 
    position: relative;
    margin-left: -20px;
    margin-right: -20px; 
}
.card {
    font-size: clamp(0.875rem, 0.8038rem + 0.3165vw, 1rem); // 360 - 992 px => 0,875 = 14px | 1 rem = 16px

    padding: 20px; 
    // flex:1;
    // margin: 1%;	
     // margin-bottom: 2%;
     display: flex; // equal height

    // services
    @at-root #{&}-service {
        
        @include media-query('md') {
            // width: 48%;
            width: 50%;
        }
    }

    // feature (first box)
    @at-root #{&}-feature {
        width: 100%;
    }

    // card - service 
    &.card-service {
        .card-inner {
            position: relative;
            display: flex;
            flex-direction: column;
            width: 100%; 
        }

        .card-header {
            text-align: center;
            margin-bottom: 2rem;
            .image-ar {
                margin: 0 auto;
                // height: 300px; 
                // max-width: 300px;
                width: 210px;
                height: 210px;
                @include media-query('lg') {
                    width: 280px;
                    height: 280px;
                }
                @include media-query('xl') {
                    width: 320px;
                    height: 320px;
                }
            }
            .object-rounded {
                border-radius: 50%;
            }
        }

        // .card-body {}
    }

    // card - feature (service) / 1st item
    // colors and modifiers (feature)
    &.card-feature {
        position: relative;

        @include media-query('md') {
            margin-bottom: 2rem;
        }

        .card-inner {
            position: relative;
            display: flex;
            flex-direction: column;
            width: 100%; 
            @include media-query('md') {
                flex-direction: row;
            }
        }
        .card-header {
            padding: 40px 0 40px 0; 
            @include media-query('md') {
                padding: 40px 20px 40px 0; 
                width: 50%; 
            }
        }
        .card-body {
            
             /* INNER .card-body-inner */ 
            > * {
                @include media-query('md') {
                    text-align: left;
                    padding: 40px; 

                    .collapse ul {
                        padding: 0;
                    }
                    .collapse ul li {
                        text-align: inherit;
                        padding-left: 0;
                    }
                }
                @include media-query('lg') {
                    padding: 40px 0 40px 40px; 
                }
            }
        }
    }
}
.card-body {
    /* INNER .card-body-inner */ 
    > * {
        position: relative;
        max-width: 450px;
        margin: 0 auto;
        text-align: center;
    }
    // colors & fonts
    h2, h3 {
        color: var(--color-green); 
        span {
            display: block;
            font-size: clamp(0.875rem, 0.7326rem + 0.6329vw, 1rem);
            font-style: italic;
        }
    }
    .collapse {
        color: var(--color-brown); 
    }
}


.card-content, .card-header {
    position: relative;
    z-index: 1;
}
.card-content {
    padding: 40px; 
}

.card-bg {
    position: absolute;
    top: -3px;
    left: -3px;
    width: calc(100% + 6px);
    height: calc(100% + 6px);
    // border-radius: 40px;
    border-radius: 20px;
    

    transition: opacity .25s ease-in-out,box-shadow .25s ease-in-out;
    box-shadow: 0 0 30px transparent;
    z-index: 0;
}
.card-service .card-bg { 
    background: linear-gradient(180deg, #f2f1ed 20%,rgba(255,255,255,0.73) 90%);
}
.card-feature .card-bg {
    background: linear-gradient(180deg, #f2f1ed 20%,rgba(255,255,255,0.73) 90%);
    @include media-query('md') {
        background: linear-gradient(90deg, #f2f1ed 20%,rgba(255,255,255,0.73) 90%);
    }
}



