.object-fit {
	position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: hidden;

    img {
    	position: relative;
	    width: 101%;
	    height: 101% !important;
	    max-width: none;
	    top: 50%;
	    left: 50%;
	    -webkit-transform: translate(-50%, -50%);
	    transform: translate(-50%, -50%);
	    object-fit: cover;
    }

    svg {
    	display: block;
    	position: absolute;
    	top: 0;
	    left: 0;
	    width: 100%;
	    object-fit: cover;
    } 
}


.object-cover {
    object-fit: cover;
}

.object-rounded {
    border-radius: 20px;
}
.object-shadow {
    box-shadow: 5px 5px 10px rgb(0 0 0 / 35%);
}

// Maps
.map {
    overflow:hidden;
    padding-bottom: var(--map-height); // map-height-percantage 56.25%
    position:relative;
    height:0;
    border-radius: 5px;
}

.map iframe{
    left:0;
    top:0;
    height:100%;
    width:100%;
    position:absolute;
}

// relative
/*
.obj-fit-rel {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: hidden;

    img {
        position: relative;
        width: 101%;
        height: 101% !important;
        max-width: none;
        // top: 50%;
        // left: 50%;
        // -webkit-transform: translate(-50%, -50%);
        // transform: translate(-50%, -50%);
        object-fit: cover;
    }
}
*/