// SHOW

// HIDE
.hide-mobile {
    display:none;
    @include media-query('lg') {
        display: block;
    }
}
.hide-desktop {
    @include media-query('lg') {
        display: none;
    }
}

