/* 
* PAGE HEADER
*/ 
.faux-page-title-lip {
    position: relative;

    height: 100vh;
    min-height: auto!important; 
    max-height: 20px;
    padding: 0;

    @include media-query('sm') {
        margin-bottom: 40px;
    }
    @include media-query('lg') {
        margin-bottom: 260px;
    }
    @include media-query('xl') {
        margin-bottom: 340px;
    }
}
// title
.page-title {
    position: relative;
    text-transform: uppercase;
    font-weight: 500;
    // letter-spacing: 1px;
    color: var(--color-brown);
    margin-bottom: 1rem; 
    .grid-col {
        @include single-content-grid-col();
    }
}


// content
.page-content {
    position: relative;
    padding-bottom: 3rem; // faux lip visual spacing

    
    .grid-col {
        @include single-content-grid-col();
    }


    // variants (templates)
    // &[data-variant='legal'] {}
    &[data-variant='error'] {
        .text {
            min-height: 500px; 
        }
    }
    
}