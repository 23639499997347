.collapse {
    display: block;
    max-height: 0px;
    overflow: hidden;
    transition: max-height .5s cubic-bezier(0, 1, 0, 1);; 
  
    &.show {
      max-height: 99em;
      transition: max-height .5s ease-in-out;
    }
}

.collapse ul {
  
  li {
    text-align: center;
    list-style-type: none;
    white-space: normal;
    padding: 0.7625rem 1rem;
    // color: var(--color-brown);
    &:not(:last-child) {
      border-bottom: 1px solid var(--color-light-brown);
    }
    
  }
}