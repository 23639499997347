.shape {
    --h: 1rem;
    --w: 1rem; 
    height: var(--h); 
    width: var(--w); 
    
    @at-root #{&}-dot {     
        background-size: 0.75rem 0.75rem;
        opacity: .4;
    } 

    @at-root #{&}-circle {
        border-radius: 50%;
    }
    
    &.rellax {
        position: absolute;
        z-index: 1;
    }

    &.has-text {
      .shape-inner {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height:100%;

        > * {
          align-self: center;
          display: block;
        }
      }
    }
}


/*colors*/
@each $color,
$value in $gorko-colors {
  .shape-dot.#{$color} {
    @include bg-dot-variant($value);
  }
}
@each $color,
$value in $gorko-colors {
  .shape-line.#{$color} {
    @include bg-line-variant($value);
  }
}
@each $color,
$value in $gorko-colors {
  .shape.#{$color} .svg-fill {
    fill: $value;
  }
  /*
  .shape.soft-#{$color} .svg-fill {
    fill: tint-color($value, $bg-soft-level);
  }
  .shape.pale-#{$color} .svg-fill {
    fill: tint-color($value, $bg-pale-level);
  }
  */
}




/*media*/
@media (max-width: 575.98px) {
    .shape {
      display: none;
    }
  }
