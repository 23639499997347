.image, 
.image .ar {
    position: relative;
    // overflow: hidden;
}

// image fill aspect ratio
.image-ar {
    position: relative;
    width: 100%;
    height: 100vw;
    margin-bottom: 3rem;
    @include media-query('lg') {
        height: 100%;
    }
}


.image-ar .ar {
    &.ar-fill {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
}

.image-ar .ar .photo {
    z-index: 2;
    position: absolute;
    width: calc(100% + 1px);
    height: calc(100% + 1px);
    display: block;
    top: 0;
    left: 0;

    img {
        width: 100%;
        height: 100%;
    }
}


