/*
* HEADER
*/ 
.header {
    position: relative;
    height: 50px;
    right: 0;
    left: 0;
    // display: flex;
    // align-items: center;
    width: 100%;
    z-index: 10;
    
    transition: top .5s cubic-bezier(.4,0,.2,1),transform .5s cubic-bezier(.4,0,.2,1),opacity .5s cubic-bezier(.4,0,.2,1),-webkit-transform .5s cubic-bezier(.4,0,.2,1);

    @include media-query('lg') {
        position: absolute;
        height: auto;
        
    }
    @include media-query('xxl') {
        padding-top: 70px; // space top TO DO refine
    }
}

/*
* header-top (Logo & Icon Boxes)
*/ 
.header-top {
    position: relative;
    z-index: z('header');

    display: flex;
    justify-content: space-between;
    align-items: center;
    
    height: 100%;
    background-color: var(--color-white);

    @include media-query('lg') {
        padding: 30px 0 55px; 
    }
    @include media-query('xxl') {
        border-radius: 40px;
        
    }
}


/*
* HEADER NAV
*/ 
@mixin layout-header-nav {
    // max-width: 1440px !important;
    width: 100%;
    max-width: 1240px; 
    // padding-left: 95px;
    // padding-right: 95px;

   
}

.header-nav-wrap {
    position: relative;
    z-index: z('desktopNav');

    &.wrapper {
        @include layout-header-nav; 
    }
}
// .header-nav-wrap-inner {}

.nav-desktop {
    position: relative; 
    background-color: var(--color-green);
    @include media-query('lg') {
        border-radius: 10px;
        top: -25px; 
    }
    // @include media-query('xl') {}

    // Hide Logo item (show when sticky header)
    .logo-icon {
        svg {
            transform: translatex(-5px);
            transition: all 0.33s ease-in-out;
            opacity: 0; 
            will-change: transform, opacity;

        }
    }
}
// HEADER NAV is sticky (JS)
.header-nav-wrap.is-sticky {
    
    .header-nav-wrap-inner {
        position: fixed;
        left: 0;
        right: 0; 
        top: 0;
        // margin-top: 0px; 
        width: 100%;
    } 
    .nav-desktop {
        top: 0px; 
        // @include layout-header-nav; 
        max-width: 1580px; 
        margin-right: auto;
        margin-left: auto;

        .logo-icon {
            svg {
                transform: translatex(0px);
                opacity: 1; 
            }
        }
    }
}

/*
* HEADER Elements Style
*/ 
.header .logo {
    display: block; 
}
.header .logo-image {
    
    align-items: center; 
}
.header .logo-full svg {
    display: flex !important;
    width: 280px; 
}
.header .logo-sm svg {
    display: flex !important;
    // width: 120px;
    width: clamp(120px, 160px + 1.1vw, 240px);  
}


