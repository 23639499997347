//----------------------------------------------------------//
//	PAGE SCROLL PROGRESS
//----------------------------------------------------------//
@media (min-width:576px) {
	.progress-wrap.active-progress {
		opacity: 1;
	}
}
.progress-wrap {
	position: fixed;
	right: 1.5rem;
	bottom: 1.5rem;
	width: 2.3rem;
	height: 2.3rem;
	cursor: pointer;
	display: block;
	border-radius: 100%;
	box-shadow: inset 0 0 0 0.1rem rgba(134, 130, 128, 0.25);
	z-index: 1040;
	opacity: 0;
	visibility: hidden;
	transform: translateY(0.75rem);
	// @include transition(all 200ms linear, margin-right 0ms);
    transition: all 200ms linear, margin-right 0ms;
	&.active-progress {
		visibility: visible;
		transform: translateY(0);
	}
	&:after {
        // https://kulturbanause.de/blog/svg-als-css-background-verwenden-und-umfaerben/
		position: absolute;
		// font-family: $font-family-unicons; // position an up arrow here
		// content: $icon-page-progress;
        content: '';
        background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" x="0px" y="0px"><g data-name="Layer 17"><path d="M28.09,16.19a1,1,0,0,1-.65.24,1,1,0,0,1-.76-.35L17,4.64V29a1,1,0,1,1-2,0V4.64L5.32,16.08a1,1,0,0,1-1.52-1.3L13.65,3.14a3.08,3.08,0,0,1,4.7,0L28.2,14.78A1,1,0,0,1,28.09,16.19Z"></path></g></svg>');
		background-repeat: no-repeat;
        background-size: 1.6rem;
        background-position: center center;
        text-align: center;
		line-height: 2.3rem;
		// @include font-size(1.2rem);
        // font-size: 1.2rem;
		// color: $main-color;
		left: 0;
		top: 0;
		height: 2.3rem;
		width: 2.3rem;
		cursor: pointer;
		display: block;
		z-index: 1;
		// @include transition(all 200ms linear);
	}
	svg path {
		fill: none;
	}
	svg.progress-circle path {
		// stroke: $main-color;
        stroke: var(--color-black);
		stroke-width: 4;
		box-sizing: border-box;
		// @include transition(all 200ms linear);
        transition: all 200ms linear; 
	}
}
@media (min-width:576px) {
	.progress-wrap.active-progress {
		opacity: 1;
	}
}