// base
a, .btn-link {
  text-decoration: none;
  color: var(--color-green); 
    &:hover {
      color: #0c7028; 
    }
}
p {
    margin-bottom: 1rem;
}

ul {

    display:block;
    margin: 0;
    padding-left: 1rem;
    margin-bottom: 1.5rem;
}
ul li { 
    // display: inline-block;
    vertical-align: middle;
    // list-style-position: inside;
    list-style-type: disc;
    text-align: left;
    // white-space: nowrap;
    padding-left: 0.5rem;
}


//headline styles
h1, h2, h3 {
  font-family: var(--font-family-headline); 
}


/*
.text h1:not(.hero-title):not(.h-hand),
.h1:not(.hero-title):not(.h-hand),
h1:not(.hero-title):not(.h-hand) {
  // font-size: 2rem;
  // margin-bottom: 3rem;
  // line-height: 1.25em;
}
*/
.text h2:not(.h-hand),
.h2:not(.h-hand),
h2:not(.h-hand) {
  // font-size: 2rem;
  font-size: clamp(1.5rem, 0.7880rem + 3.1646vw, 2.25rem);
  font-weight: 600;
  line-height: 1.25em;
  margin-bottom: 2rem;
}
.text h3:not(.h-hand),
.h3:not(.h-hand),
h3:not(.h-hand) {
  font-size: clamp(1.15rem, 0.7880rem + 3.1646vw, 1.5rem);
  font-weight: 600;
}
.h-hand {
    font-family: var(--font-family-hand);
    font-size: clamp(1.5rem, 0.7880rem + 3.1646vw, 2.25rem);
    font-weight: 400;
    margin-bottom: 0;
    line-height: 1;
     // color: var(--color-brown);
    color: var(--color-green);  

    + h2, + h1 {
      margin-top: 0!important;
    } 
}


.text {
  --flow-space: 2.5rem; 

  h1,h2,h3,h4 {
    + p {
      margin-top: 0.7rem;
    }
  }
  h2:not(:first-child) {
    margin-top: 6rem; 
  }
}


// OLD
// keep for reference
/*
.h-dotted {
    position: relative;
    margin-bottom: 30px;
    
    &:after {
        display: block;
        content: "";
        position: relative;
        height: 20px; 
        max-width: 90%;

        // DOTTED LINE
        // https://stackoverflow.com/questions/6250394/how-to-increase-space-between-dotted-border-dots
        // border-bottom: 3px dotted black;
        
        //background-image: linear-gradient(to right, black 20%, rgba(255,255,255,0) 70%);
        //background-position: bottom;
        //background-size: 4px 2px;
        //background-repeat: repeat-x;
     

        // https://stackoverflow.com/questions/28326139/rounded-dotted-border-spacing-in-css
        
        //position: absolute;
        //top: 20px;
        //left: 20px;
        //content:'';
        //background: black;
        //height: 5px;
        //width: 5px;
        //box-shadow: 50px 0px 0px black, 100px 0px 0px black, 150px 0px 0px black, 200px 0px 0px black, 250px 0px 0px black, 0px 190px 0px black, 50px 190px 0px black, 100px 190px 0px black, 150px 190px 0px black, 200px 190px 0px black, 250px 190px 0px black, 0px 47.5px 0px black, 0px 95px 0px black, 0px 142.5px 0px black, 0px 47.5px 0px black, 250px 47.5px 0px black, 250px 95px 0px black, 250px 142.5px 0px black;
        //
    
    }
}
*/