// Reset buttons
button, input, optgroup, select, textarea {
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
}
button, input {
    overflow: visible;
}
button, select {
    text-transform: none;
}
button, input, label, select, textarea {
    display: inline-block;
    vertical-align: middle;
    font: inherit;
    letter-spacing: inherit;
    border: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: none;
    border-radius: 0;
    color: inherit;
    outline: 0;
    white-space: normal;
}
button {
    letter-spacing: inherit;
    text-transform: inherit;
    background: transparent;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
a, button, input, label, select, summary, textarea {
    -ms-touch-action: manipulation;
    touch-action: manipulation;
}
[type=button], [type=reset], [type=submit], button {
    -webkit-appearance: button;
}

// Main Menu Button
.btn-menu-wrap {
    width: 70px;
}
.btn-menu {
    position: relative;
    // z-index: 10;
    padding: 20px;
    margin: -20px;

    &:hover {
        .btn-menu-burger {
            &:before {
                transform: translateY(200%);
            }
            &:after {
                transform: translateY(-200%);
            }
        }
    }
    &.is-active {
        .btn-menu-burger {
            &:before {
                transform: translateY(200%) rotate(45deg);
            }
            &:after {
                transform: translateY(-200%) rotate(-45deg);
            }
        }
    }
}
.btn-menu-burger {
    position: relative;
    display: block;
    width: 25px;
    height: 10px;
    cursor: pointer;

    &:before, &:after {
        content: "";
        position: absolute;
        left: 0;
        width: 100%;
        height: 2px;
        background: #131313;
        transition: transform .5s cubic-bezier(.86,0,.07,1),-webkit-transform .5s cubic-bezier(.86,0,.07,1);
    }
    &:before {
        top: 0;
    }
    &:after {
        bottom: 0;
    }    
}

// btn-underline
/*
.btn-underline {
    font-size: 14px;
    
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
    position: relative;
    padding-bottom: 4px;

    &:after {
    
        content: "";
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        height: 1px;
        opacity: .4;
        background: #131313;
        -webkit-transition: opacity .5s cubic-bezier(.4,0,.2,1);
        transition: opacity .5s cubic-bezier(.4,0,.2,1);

    }

    &:hover:after {
        opacity: 1;
    }
}
*/

// BTN
.btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.7;
    letter-spacing: normal;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    text-decoration: none;
    cursor: pointer;
    user-select: none;
    border: 2px solid transparent;
    box-sizing: border-box;
    padding: 0.4rem 1rem;
    margin-top: 0.825rem;
    transform: translateY(0);
    transition: all .2s ease-in-out;
   
    &:hover {
        transform: translateY(-0.15rem);
        box-shadow: 0 0.25rem 0.75rem rgb(30 34 40 / 15%);
        color: #fff;
    }
}

.btn-small {
    font-size: 0.825rem;
    padding: 0.25rem 0.5rem;
    margin-top: 0.625rem;
}

.rounded-pill {
    border-radius: 50rem!important;
}

.btn-green {
    color: #fff;
    background-color: var(--color-green);
    border-color: var(--color-green);
}
.btn-white {
    color: #313131;
    background-color: var(--color-light);
    border-color: var(--color-light);
    &:hover {
        background-color: var(--color-green);
        color: var(--color-light);
    }
}
.btn-light-green {
    color: var(--color-green);
    background-color: var(--color-light-green);
    border-color: var(--color-light-green);
    &:hover {
        color: #313131;
    }
}
.btn-violet {
    color: #fff;
    background-color: var(--color-violet);
    border-color: var(--color-violet);
}
.btn-light-violet {
    // color: #313131;
    color: var(--color-violet);
    background-color: var(--color-light-violet);
    border-color: var(--color-light-violet);
    &:hover {
        color: #313131;
    }
}
.btn-brown {
    color: #fff;
    background-color: var(--color-brown);
    border-color: var(--color-brown);
}